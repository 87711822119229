import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

/* ====================================================
    Adding class to mobile main menu
   ==================================================== */ 
$(document).ready(function($){
    // Get current url
    // Select an a element that has the matching href and apply a class of 'active'. Also prepend a - to the content of the link
    var url = window.location.href;

    // Will also work for relative and absolute hrefs
   

    $('ul.menu a').each(function () {
        if (this.href == window.location.href) {
            $(this).parents('li').find('> a').addClass("active");
        }
    });

    $('ul.offcanvas-full-screen-menu a').each(function () {
        if (this.href == window.location.href) {
            $(this).parents('li').addClass("active");
        }
    });

    $('.submenu-toggle[aria-expanded="false"]').on( 'click', function () { 
         $('.has-submenu-toggle').addClass('open'); 
    }); 


});


/* ====================================================
    Algorithm
   ==================================================== */ 
$(document).ready( function () {
        
            $('.q-serum .search-plus').on( 'click', function () { 
                 $('.intro').addClass('shrink'); $('.q-serum').addClass('shrink'); $('.q-marrow').removeClass('q-hide');
                 $('.serum.footnotes').addClass('q-hide'); $('.button.solid').removeClass('b-hide');
            }); 

            $('.q-serum .search-minus').on( 'click', function () { 
                $('.intro').addClass('shrink'); $('.q-serum').addClass('shrink'); $('.q-ttr').removeClass('q-hide');
                $('.serum.footnotes').addClass('q-hide'); $('.button.solid').removeClass('b-hide');
            });

            $('.q-marrow .search-plus').on( 'click', function () { 
                 $('.q-marrow').addClass('shrink'); $('.q-marrow-consider').removeClass('q-hide');  
            });

            $('.q-marrow .search-minus').on( 'click', function () { 
                 $('.q-marrow').addClass('shrink'); $('.q-marrow-endomyocardial').removeClass('q-hide'); 
            });
            $('.q-marrow-consider .search-plus').on( 'click', function () { 
                 $('.q-marrow-consider').addClass('shrink'); $('.finish.ALamyloidosis').removeClass('q-hide');  
            });
             $('.q-marrow-consider .search-minus').on( 'click', function () { 
                 $('.q-marrow-consider').addClass('shrink'); $('.finish.unlikely').removeClass('q-hide');  
            });
            $('.q-marrow-endomyocardial .search-plus').on( 'click', function () { 
                 $('.q-marrow-endomyocardial').addClass('shrink'); $('.q-organ').removeClass('q-hide'); 
            });
            $('.q-organ .search-plus').on( 'click', function () { 
                 $('.q-organ').addClass('shrink'); $('.finish.ALamyloidosis').removeClass('q-hide'); 
            });
            $('.q-organ .search-minus').on( 'click', function () { 
                 $('.q-organ').addClass('shrink'); $('.finish.unlikely').removeClass('q-hide'); 
            });
            $('.q-marrow-endomyocardial .search-minus').on( 'click', function () { 
                 $('.q-marrow-endomyocardial').addClass('shrink'); $('.finish.unlikely').removeClass('q-hide'); 
            });

            

            $('.q-ttr .search-plus').on( 'click', function () { 
                $('.q-ttr').addClass('shrink'); $('.q-ttr-mass').removeClass('q-hide'); 
            });
            $('.q-ttr-mass .search-plus').on( 'click', function () { 
                $('.q-ttr-mass').addClass('shrink'); $('.finish.suspected').removeClass('q-hide'); 
            });
             $('.q-ttr-mass .search-minus').on( 'click', function () { 
                $('.q-ttr-mass').addClass('shrink'); $('.finish.wild').removeClass('q-hide'); 
            });
            $('.q-ttr .search-minus').on( 'click', function () { 
                $('.q-ttr').addClass('shrink'); $('.q-ttr-endomyocardial').removeClass('q-hide'); 
            });
            $('.q-ttr-endomyocardial .search-plus').on( 'click', function () { 
                $('.q-ttr-endomyocardial').addClass('shrink'); $('.q-ttr-mass').removeClass('q-hide'); 
            });
            $('.q-ttr-endomyocardial .search-minus').on( 'click', function () { 
                $('.q-ttr-endomyocardial').addClass('shrink'); $('.finish.ruleout').removeClass('q-hide'); 
            });
            $('#restartBTN').on( 'click', function () { 
                $('.q-serum').removeClass('shrink');
                $('.q-marrow').removeClass('shrink');
                $('.q-marrow-consider').removeClass('shrink'); 
                $('.q-marrow-endomyocardial').removeClass('shrink'); 
                $('.q-organ').removeClass('shrink'); 
                $('.q-ttr').removeClass('shrink'); 
                $('.q-ttr-endomyocardial').removeClass('shrink'); 
                $('.q-ttr-mass').removeClass('shrink'); 

                $('.q-marrow').addClass('q-hide');
                $('.q-marrow-consider').addClass('q-hide'); 
                $('.q-marrow-endomyocardial').addClass('q-hide'); 
                $('.q-organ').addClass('q-hide'); 
                $('.q-ttr').addClass('q-hide'); 
                $('.q-ttr-endomyocardial').addClass('q-hide'); 
                $('.q-ttr-mass').addClass('q-hide'); 
                $('.finish').addClass('q-hide'); 
                  
            });

            // $('#restartBTN').click(function(){
            //       $('#restartAlgo').load('symptoms_and_testing.html #restartAlgo', function() {
            //            /// can add another function here
            //       });
            //    });

             // $("#restartBTN").click(function() {
             //    //alert('clicked')
             //    $("#restartAlgo").load("#restartAlgo *");
             //  }); 


}); 

/* ====================================================
    Reveal Timeline
   ==================================================== */ 
$(document).ready(function() {

    // init controller
    var controller = new ScrollMagic.Controller();

    //build a scene
    var revealElements = document.getElementsByClassName("appear");
    for (var i=0; i<revealElements.length; i++) { // create a scene for each element
        new ScrollMagic.Scene({
                            triggerElement: revealElements[i], // y value not modified, so we can use element as trigger as well
                            offset: 0,                                              // start a little later
                            triggerHook: 0.55,
                            reverse: false,
                        })
                        .setClassToggle(revealElements[i], "visible") // add class toggle
                        .addTo(controller);
    }



    if ($(".tab-search").hasClass("is-active")) {
        var revealElements2 = document.getElementsByClassName("appear2");
        for (var i=0; i<revealElements2.length; i++) { // create a scene for each element
            new ScrollMagic.Scene({
                                triggerElement: revealElements2[i], // y value not modified, so we can use element as trigger as well
                                offset: 0,                                              // start a little later
                                triggerHook: 0.72,
                                reverse: false,
                            })
                            .setClassToggle(revealElements2[i], "visible") // add class toggle
                            .addTo(controller);
        }
    } 
    else if ($("#subtype").hasClass("is-active")) {

        var revealElements3 = document.getElementsByClassName("appear3");
        for (var i=0; i<revealElements3.length; i++) { // create a scene for each element
            new ScrollMagic.Scene({
                                triggerElement: revealElements3[i], // y value not modified, so we can use element as trigger as well
                                offset: 0,                                              // start a little later
                                triggerHook: 0.74,
                                reverse: false,
                            })
                            .setClassToggle(revealElements3[i], "visible") // add class toggle
                            .addTo(controller);
        }
    }

    $('#tab2').on( 'click', function () { 
            var revealElements4 = document.getElementsByClassName("appear2");
            for (var i=0; i<revealElements4.length; i++) { // create a scene for each element
                new ScrollMagic.Scene({
                                    triggerElement: revealElements4[i], // y value not modified, so we can use element as trigger as well
                                    offset: 0,                                              // start a little later
                                    triggerHook: 0.72,
                                    reverse: false,
                                })
                                .setClassToggle(revealElements4[i], "visible") // add class toggle
                                .addTo(controller);
            }
    });

    $('#tab3').on( 'click', function () { 
            var revealElements5 = document.getElementsByClassName("appear3");
            for (var i=0; i<revealElements5.length; i++) { // create a scene for each element
                new ScrollMagic.Scene({
                                    triggerElement: revealElements5[i], // y value not modified, so we can use element as trigger as well
                                    offset:0,
                                    triggerHook: 0.74,
                                    reverse: false,
                                })
                                .setClassToggle(revealElements5[i], "visible") // add class toggle
                                .addTo(controller);
            }
    }); 
    
});



$('#tab1').on( 'click', function () { 
     $('#symptoms-test .container-box').addClass('symptoms-man-margin');
}); 

$('#tab2,#tab3').on( 'click', function () { 
     $('#symptoms-test .container-box').removeClass('symptoms-man-margin');
}); 





// if ($("#symptoms").hasClass("is-active")) {
//     $('.container-box').addClass('symptoms-man-margin');
     
// }
// else if ($("#search").hasClass("is-active")) {
//     $('.container-box').removeClass('symptoms-man-margin');
     
// }
// else if ($("#subtype").hasClass("is-active")) {
//     $('.container-box').removeClass('symptoms-man-margin');
     
// }



/* ====================================================
    Link Anchor
   ==================================================== */

   // for href link to #tag in different page
     setTimeout(function () {
           $('html,body').animate({
             scrollTop: $(window.location.hash).offset().top-200
           });
     },300);